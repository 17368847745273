import $ from "jquery";
import debounce from "lodash/debounce";

new (class {
    constructor() {
        this.menuActiveContainer;
        this.menuActiveContainerLeft;
        this.openNew;

        this.mouseOverMainnav = false;
        this.mouseOverMegamenu = false;

        // init upper categories

        const children = $("#upper-cats .row").children();
        const selected = "Maschinen";
        console.log(children);
        for (let index = 0; index < children.length; index++) {
            const element = children[index];
            const target = element.dataset.target;
            console.log(selected, target);
            if (target !== selected) {
                $(".categories ." + target).addClass("d-none");
                $(element).removeClass("bg-primary text-secondary").addClass("bg-secondary text-primary");
            } else {
                $(element).removeClass("bg-secondary text-primary").addClass("bg-primary text-secondary");
                $(".categories ." + selected).removeClass("d-none");
            }
        }

        // Set up event listeners
        $("#mainnav .navbar-content .categories > li > a").mouseover((event) => {
            this.openMenu($(event.currentTarget).data("target"));
        });

        $("#upper-cats .col-sm").mouseover((event) => {
            const children = $("#upper-cats .row").children();
            const selected = event.currentTarget.dataset.target;
            console.log(children);
            for (let index = 0; index < children.length; index++) {
                const element = children[index];
                const target = element.dataset.target;
                console.log(selected, target);
                if (target !== selected) {
                    $(".categories ." + target).addClass("d-none");
                    $(element).removeClass("bg-primary text-secondary").addClass("bg-secondary text-primary");
                } else {
                    $(element).removeClass("bg-secondary text-primary").addClass("bg-primary text-secondary");
                    $(".categories ." + selected).removeClass("d-none");
                }
            }
        });

        $("#megamenu").mouseover(() => {
            this.mouseOverMegamenu = true;
        });
        $("#mainnav").mouseover(() => {
            this.mouseOverMainnav = true;
        });

        $("#mainnav").mouseleave(
            debounce(() => {
                this.mouseOverMainnav = false;
                this.closeMenu();
            }, 100)
        );

        $("#megamenu").mouseleave(
            debounce(() => {
                this.mouseOverMegamenu = false;
                this.closeMenu();
            }, 100)
        );

        $(".has-megamenu").click(function (e) {
            if (e.target !== this) return;

            $(".has-megamenu.open").not(this).removeClass("open");

            e.preventDefault();
            $(e.currentTarget).toggleClass("open");
        });

        $(".mobile-menu-col a").click(() => {
            $(".menurow, .toprow").toggleClass("open");
            $("body").toggleClass("open-menu");

            $(".menurow").css("max-height", $(".menurow").hasClass("open") ? window.innerHeight - 260 + "px" : "");
        });
        $(".wrapper").click(() => {
            if ($("body").hasClass("open-menu")) {
                $(".menurow, .toprow").removeClass("open");
                $("body").removeClass("open-menu");
                $(".menurow").css("max-height", "");
            }
        });
    }

    // CLOSE MENU FUNCTION
    closeMenu() {
        if (this.mouseOverMainnav || this.mouseOverMegamenu) return;

        $("#megamenu").removeClass("open");
        $("#mainnav .navbar-content a").removeClass("opened");
        $("#mainnav .navbar-content ul.categories").removeClass("megamenu-open");
    }

    // OPEN MENU FUNCTION
    openMenu(target) {
        this.openNew = !$("#megamenu").hasClass("open");
        this.menuActiveContainer = $(`#megamenu .menu-container[data-id="${target}"]`);
        if (this.menuActiveContainer.length === 1) {
            const width = $("#megamenu .menu-container").index(this.menuActiveContainer) * 100;
            this.menuActiveContainerLeft = `-${width}%`;

            $("#megamenu").addClass("open");
            if (this.openNew) {
                $("#megamenu .track").addClass("notransition");
            } else {
                $("#megamenu .track").removeClass("notransition");
            }

            $("#megamenu .track").css({ left: this.menuActiveContainerLeft });

            $("#mainnav .navbar-content a").removeClass("opened");
            $(`#mainnav .navbar-content a[data-target="${target}"]`).addClass("opened");
            $("#mainnav .navbar-content ul.categories").addClass("megamenu-open");
        } else {
            this.closeMenu();
        }
    }
})();
